.modal:not(.bootbox){
    .modal-header{
        background: $primary;
        color: $white;
        .modal-title{
            font: 600 18px/32px Poppins, sans-serif;
        }
    }
    .modal-footer{
        background: rgba(233, 226, 255, 0.4);
    }
}

