// Sidebar

/*******************
Main sidebar
******************/

.left-sidebar {
    position: fixed;
    width: 225px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    padding-top: 0;
    background: $sidebar;
    transition: 0.3s ease-in;
    border-right: $border;

    .nav-text-box{
        padding: 13px 25px 13px 15px;
        border-bottom: 1px solid $border-color;
    }

}

.is-collapsed .left-sidebar{
    width: 70px;
}

#logo{
    padding: 39px 13px 44px;
}

/*******************
sidebar navigation
******************/

.scroll-sidebar {
    height: calc(100% - 140px);
    position: relative;
    overflow-y: auto;
}

.scroll-sidebar::-webkit-scrollbar{
    width: 0.15rem;
}
.scroll-sidebar::-webkit-scrollbar-thumb{
    background: rgba($primary, 0.6);
    outline: 0.3px solid $info;
}
.scroll-sidebar::-webkit-scrollbar-track{
    background: $white;
}

.collapse.in {
    display: block;
}

.sidebar-nav {
    overflow: hidden;
    padding: 0;
    border-right: 1px solid #EFEEF3;
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            a {
                position: relative;
                color: $sidebar-text;
                padding: 13px 15px 12px 64px;
                display: block;
                align-items: center;
                min-height: 49px;
                white-space: nowrap;
                font-weight: 500;

                i, svg {
                    position: absolute;
                    left: 24px;
                    line-height: 0;
                    font-size: 1.5rem;
                    color: $primary;
                }

                svg {
                    fill: $primary;
                    width: 24px;
                    height: 24px;
                }

                &.active{
                    font-weight: bold;
                    color: $gray-800;
                    .icon{
                        color: $white;
                    }
                    svg{
                        fill: white;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        left: 18px;
                        top: 8px;
                        width: 36px;
                        height: 36px;
                        background: $primary;
                        border-radius: 50%;
                        z-index: -1;
                    }
                }
                &:hover {
                    background: #E9E2FF;
                    text-decoration: none;
                    .icon{
                        color: $primary;
                    }
                    svg{
                        fill: $primary;
                    }
                }
            }
            ul {
                padding: 10px 0 10px 0;
                background:rgba(0, 0, 0, 0.02);

                li a {
                    padding: 7px 15px 7px 20px;
                }
                ul {
                    padding-left: 15px;
                }
            }
            &.nav-small-cap {
                font-size: 12px;
                padding: 3px 0px 3px 5px;
                margin: 1px 0;
                color: $text-muted;
                font-weight: 500;
                background: #E9E2FF;
            }
            &.nav-devider {
                height: 1px;
                background: $border-color;
                display: block;
                margin: 15px 0;
            }

        }
    }
}

.is-collapsed .sidebar-nav .hide-menu{
    display: none;
}


/*******************
   Window Scroll
******************/

body::-webkit-scrollbar {
    width: 11px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: $primary rgba($primary, 0.1);
  }
  body::-webkit-scrollbar-track {
    background: rgba($primary, 0.1);
  }
  body::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 6px;
    border: 3px solid rgba($primary, 0.1);
  }
