.bootbox{

    &.bootbox-icon{
        .bootbox-body:before{
            display: block;
            padding: 2rem 0;
            font-family: 'Line Awesome Free';
            font-weight: 900;
            line-height: 1;
            content: "";
            font-size: 5rem;
        }
    }

    &.bootbox-success{
        .bootbox-body{
            &:before{
                content: "\f058";
                color: $success;
            }
        }
    }

    &.bootbox-warning{
        .bootbox-body{
            &:before{
                content: "\f06a";
                color: $danger;
            }
        }
    }

    .btn{
        min-width: 116px;
    }

    .modal-header{
        padding: 2.5rem 0 1.5rem;
        .modal-title{
            width: 100%;
            text-align: center;
            font: 600 22px/30px , sans-serif;
        }
        .close{
            padding: 0;
            width: 28px;
            height: 28px;
        }
        .bootbox-close-button{
            position: absolute;
            right: 2.5rem;
            top: 2.5rem;
            overflow: hidden;
            font-family: 'Line Awesome Free';
            font-size: 28px;
            font-weight: 900;
            color: $primary;
            opacity: 1;
            outline: none;
            &:before{
                content: "\f00d";
            }
        }
    }

    .bootbox-body{
        padding: 0 2rem;
        text-align: center;

        .las{
            font-size: 65px;
        }
    }

    .modal-footer{
        justify-content: center;
        flex-direction: row-reverse;
        padding: 2.5rem;
    }
}

.modal{
    .modal-body{
        text-align: left;
    }
}
