.btn{
    white-space: nowrap;
    .las{
        position: relative;
        font-size: 20px;
    }
    &.btn-sm{
        .las{
            font-size: 1rem;
        }
    }
    &.btn-light{
        color: $primary;
    }
}
