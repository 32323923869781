.app{
    &:not(.no-sidebar){
        .page-container{
            padding-left: 225px;
        }
        .content-wrapper{
            padding: 52px 43px 52px 60px;
        }
    }
    &.is-collapsed{
        .page-container{
            padding-left: 70px;
        }
    }
    &.no-sidebar{
        .content-wrapper{
            padding: 52px 0;
        }
    }
}

.page-container{
    min-height: 100vh;
    transition: 0.3s ease-in;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content-wrapper{
    flex: 1;
}

.p-side{
    padding: 0 43px 0 60px;
}
