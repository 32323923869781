#sets-form {
    .sets-nav {
        .btn-group:first-child {
            .btn {
                margin-right: 1rem;
            }
        }
    }

    .circle-btn {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        color: $primary;
        background: transparent;
        border: 0;

        .circle-figure {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            width: 46px;
            height: 46px;
            font-weight: bold;
            font-size: 24px;
            color: $white;
            border-radius: 50%;
            background: $primary;
        }
    }

    .appliance-separator {
        margin: 2rem 0 52px;

        &.customTrack {
            margin: 2rem 0;
        }
    }

    .appliance-counters {
        font-size: 14px;
        color: #69788C;
        letter-spacing: .3px;

        strong {
            font-size: 1rem;
            color: #252733;
            margin: 0 32px 0 8px;
        }
    }

    .software_id {
        min-width: 150px;
    }
}

.model-wrapper {
    padding: 1.5rem 0;

    .model-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin: 0 16px 16px 0;
        font-size: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $primary;
        @extend .btn;

        &.selected {
            position: relative;
            border-color: transparent;
            box-shadow: 0 0 0 2px $primary;

            &:before {
                content: '';
                position: absolute;
                bottom: -1px;
                right: -8px;
                width: 16px;
                height: 16px;
                background: url("/img/icons/check_circle.svg") no-repeat;
            }
        }

        &.model-printed {
            background: #D8DCFF;
            box-shadow: 0 0 0 2px #D8DCFF;
            color: $primary;
        }

        &.model-aligner {
            background: $primary;
            box-shadow: 0 0 0 2px $primary;
            color: $white;
        }

        &:disabled {
            background: $white;
            box-shadow: 0 0 0 1px $secondary;
            opacity: 1;
            color: $secondary;
        }
    }
}

#edit-devices-modal {
    .model-row {

        .model-icon {
            padding: 1rem .5rem;
        }

        .model-position {
            padding: 1rem .5rem;
        }

        .model-action {
            padding: 1rem .5rem;
            flex: 100%;
            text-align: center;
        }

        .model-delete-btn {
            width: 50px;
            padding: 1rem .5rem;
        }

        .custom-checkbox {
            font-weight: normal;
            display: flex;
        }
    }

    select {
        padding: 5px;
        height: auto;
        font-size: inherit;
    }

    .date-printed {
        position: relative;
        left: 10px;
        margin: 5px 0;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: $secondary;
    }

    .add-reprint-btn {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }
}

.ko-editable {
    position: relative;

    .ko-editable-placeholder {
        padding: 0.38rem 0.75rem;
        min-width: 150px;
        min-height: 37px;
        border: 1px solid $primary;
        border-radius: 0.25rem;

        &:after {
            display: none;
            content: "";
            font-family: "Line Awesome Free";
            font-weight: 900;
            position: absolute;
            right: 0.5rem;
            bottom: 0;
            color: rgb(95, 69, 156);
            font-size: 1.5rem;
        }

        &:hover {
            background: rgba(233, 226, 255, 0.6);
            cursor: pointer;

            &:after {
                display: block;
            }
        }
    }

    .ko-action-btn {
        position: absolute;
        border: 0;
        background: transparent;
        font-size: 12px;
        color: $primary;
        outline: none;
        box-shadow: none;
    }

    .ko-editable-save {
        right: 50px;
    }

    .ko-editable-cancel {
        right: 0;
    }
}
