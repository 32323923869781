.stage-component{
    overflow-x: auto;

    &::-webkit-scrollbar{
        height: 0.3rem;
    }
    &::-webkit-scrollbar-thumb{
        background: rgba($primary, 0.6);
    }
    &::-webkit-scrollbar-track{
        background: $white;
    }

    .stage-list{
        display: flex;
        list-style: none;
        padding: 0;
    }

    .stage-item{
        position: relative;
        min-height: 270px;
        font-weight: bold;

        .stage-icon{
            margin-bottom: 1rem;
            width: 72px;
            height: 72px;
            font-size: 53px;
            color: $white;
            border-radius: 50%;
            text-align: center;
        }

        &:nth-of-type(1){
            &:before{
                display: none;
            }
        }

        .stage-desc{
            margin-bottom: 24px;
            min-height: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;
        }

        .stage-count{
            font: 600 32px/32px, sans-serif;
            text-transform: capitalize;
            margin-bottom: 1rem;
        }

        a{
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.3px;
        }
    }

    .stage-item-inner{
        width: 180px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 1rem 2rem 1rem 2.2rem;
        margin-right: 25px;
        background: #fff;
        height: 100%;
        border-radius: 6px;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 135px solid transparent;
            border-top: 130px solid transparent;
            border-left: 25px solid $body-bg;
        }
        &:after{
            content: '';
            position: absolute;
            top: 5px;
            right: 0;
            border-bottom: 130px solid $body-bg;
            border-top: 130px solid $body-bg;
            border-left: 25px solid $white;
        }
        &:hover{
            background: lighten($primary, 20%);
            color: $white;
            a{
                color: $white;
            }
            &:after{
                right: 0;
                border-left: 25px solid lighten($primary, 20%);
            }
        }
    }
}

@each $item, $color in $colors {
    .color-#{$item} {
        background: $color;
    }
}

.stage-name-title {
    display: flex;
    justify-content: center;
    justify-items: center;
    color: $primary;
    position: relative;


    &::before {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        background: $primary;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
    }

    &__block {
        background: $body-bg;
        z-index: 1;
        border-left: 2px solid $primary;
        border-right: 2px solid $primary;
        padding: 012px;
        justify-self: center;
        height: 36px;
        display: flex;
        align-items: center;

        div {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.3px;
        }
    }
}
