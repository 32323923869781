form {
    .invalid-feedback {
        display: block;
    }

    label{
        font-weight: bold;
        font-size: 14px;
    }

    legend {
        width: auto;
        padding: 1rem;
        font: bold 1.2rem/1.4, sans-serif;
    }

    fieldset {
        padding: 1rem;
        border-radius: .25rem;
        border: 1px solid #E2E3E9;
        margin-bottom: 2rem;
    }
}

.was-validated{
    .form-control:valid{
        border: 1px solid #ced4da;
        background-image: none;
    }
    .form-control:valid + .invalid-feedback{
        display: none;
    }
}

.validated {
    .form-control + .invalid-feedback {
        display: block;
    }
}

.edit-in-place{
    .form-group, .floating-label{
        position: relative;
        input:disabled,
        select:disabled,
        textarea{
            border: 1px solid rgba($primary, 1);
        }
        &:after{
            display: none;
            content: "\f044";
            font-family: 'Line Awesome Free';
            font-weight: 900;
            position: absolute;
            right: .5rem;
            bottom: 0;
            font-size: 1.5rem !important;
            color: #5F459C;
        }
        &:hover{
            .is_editable{
                background: rgba(233, 226, 255, 0.6);
            }
            &:after{
                display: block;
            }
        }

        &.is_editable{
            .form-control{
                // background: $white;
                line-height: 1.65;
                min-height: 37px;
                background: rgba($primary, 0.1);
            }
            &:after{
                display: none;
            }

            &>select{ //Dropdown padding fix
                padding: 0.4rem 0.5rem;
            }
        }

        .action-btn{
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
        }

    }

    .editable_save,
    .editable_cancel{
        position: absolute;
        border: 0;
        background: transparent;
        font-size: 12px;
        color: $primary;
        outline: none;
    }

    .editable_cancel{
        right: 0;
    }
    .editable_save{
        right: 50px;
    }

    .is_editable{
        cursor: pointer;
        .editable_placeholder{
            display: none;
        }
        .single_edit_btn{
            display: none;
        }
        &.is-invalid{
            border: 1px solid red;
        }
    }

    .editable_placeholder{
        min-height: 37px;
        padding: 0.688rem 0.875rem;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.65;
        border: 1px solid rgba($primary, 1);

        &:hover{
            background: rgba(233, 226, 255, 0.6);
            cursor: pointer;
        }
    }
}

