.dataTables_wrapper {
    background: $white;
    padding: 30px;
    border-radius: 4px;
    overflow-x: auto;

    // Scroll bar style added to tables
    scrollbar-width: thin;
    scrollbar-color: $primary rgba($primary, 0.1);
        
    &::-webkit-scrollbar {
        height: 11px;
    }
    &::-webkit-scrollbar-track {
        background: rgba($primary, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $primary;
        border-radius: 6px;
        border: 3px solid rgba($primary, 0.1);
    }
    // ==

    .dataTables_filter input,
    .dataTables_length select {
        border: 1px solid #e2e3e9 !important;
        border-radius: 6px !important;
        padding: 0.375rem 0.75rem !important;
    }

    .datepicker {
        width: 120px !important;
    }

    .dt-header,
    .dt-footer {
        display: flex;
        align-items: center;
    }

    .dt-footer {
        justify-content: space-between;
    }

    .dataTables_filter {
        float: none;
        margin-right: 2rem;

        input {
            margin-left: 5px;
            outline: 0;
        }
    }

    .dataTables_length {
        float: none;

        select {

        }
    }

    .dataTables_paginate {
        span {
            .paginate_button {
                padding: 3px 9px;
                border: 0 !important;
                border-radius: 6px;
                color: #252733;

                &:hover {
                    background: transparent;
                    color: #252733 !important;
                }

                &.current, &.current:hover {
                    background: $primary;
                    color: $white !important;
                }
            }
        }
    }
}

table.dataTable, table.table {
    width: 100%;
    margin: 0 0 1.5rem;

    thead {
        th, td {
            border-bottom: 2px solid #E2E3E9;
            background-image: none !important;
        }

        .sorting,
        .sorting_asc {
            position: relative;

            &:before, &:after {
                position: absolute;
                font-family: 'Line Awesome Free';
                font-weight: 900;
                font-size: 12px;
                left: 0;
            }

            &:before {
                top: 7px;
                content: "\f106";
            }

            &:after {
                content: "\f107";
                bottom: 7px;
            }
        }
    }

    tbody {
        tr {
            &:nth-last-of-type(odd) {
                background: rgba(233, 226, 255, 0.4);
            }

            td {
                padding: 14px 12px;
            }
        }
    }

    tfoot {
        margin-top: 1.5rem;

        td, th {
            border-top: 0;
            padding: 0;
        }
    }

    &.no-footer {
        border-bottom: 0;
    }


    .las {
        font-size: 1.5rem !important;
        color: $primary;
    }

}

/* Reordering Tables */
/*.dataTable {
    tbody {
        tr {
            &.dt-rowReorder-moving {
                background: $primary !important;
                color: $white;
            }
        }
    }
}*/

table.dt-rowReorder-float {
    position: absolute !important;
    opacity: 0.8;
    table-layout: fixed;
    outline: 2px solid #888;
    outline-offset: -2px;
    z-index: 2001
}

tr.dt-rowReorder-moving {
    outline: 2px solid #555;
    outline-offset: -2px
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden
}

table.dataTable td.reorder {
    text-align: center;
    cursor: move
}
