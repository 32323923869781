// Body
$body-bg: #aaafb9;
$body-color: #252733;


// Typography
$font-family-base: 'Red Hat Display', sans-serif;
$font-size-base: .875rem;
$line-height-base: 1.6;

$headings-font-family: 'Red Hat Display', sans-serif;
$headings-font-weight: 600;

// Colors
$primary: #5F459C;
$info: lighten($primary, 60%);
$warning: #FFE4CF;
$danger: #BB2D3B;
$secondary: #69788C;
$purple: #8B76C2;

$white: #fff;

$theme-colors: (
    "purple": $purple,
    "light": #E9E2FF,
    "info": #229FBE
);

$sidebar: #fff;
$sidebar-text: #252733;
$sidebar-icons: $primary;
$lft: left;
$rgt: right;
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$themecolor: #009efb;

$border: 1px solid rgba(159, 162, 180, 0.3);

// Forms
$input-font-family: 'Red Hat Display';
$input-line-height: 1.5;
$input-color: #252733;
$input-border-color: #AFB9C5;
$input-placeholder-color: #252733;

/* input default */
$input-font-size: 1rem;
$input-padding-y: 0.688rem;
$input-padding-x: 0.875rem;

/* input large */
$input-font-size-lg: $font-size-base;
$input-padding-y-lg: .95rem;
$input-padding-x-lg: .95rem;
$form-check-input-checked-color: $primary;

$input-disabled-bg: #bdc0c8;
$input-disabled-border-color: $white;

$form-select-disabled-bg: $white;
$form-select-disabled-border-color: $white;


// Buttons

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1rem;
$btn-font-weight: 600;

// Breadcrumbs

$breadcrumb-bg: $body-bg;

// Modals

$modal-content-border-color: #fff;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;
$modal-footer-margin-between: 1.5rem;

// Accordion
$accordion-border-radius: 0;

// Toast
$toast-background-color: tranparent;

/* Card*/
$card-cap-bg: $white;
$card-border-width: 0;
