.template-auth {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;

    .auth-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 1060px;
        max-height: 840px;
        background: $white;
        border-radius: 1rem;
        box-shadow: 30px 30px 60px rgba(0, 0, 0, 0.2);
        overflow: hidden;

        .auth-side-img, .auth-main {
            width: 100%;
        }
    }

    .auth-side-img {
        position: relative;
        width: 100%;
        max-width: 442px;
        padding: 70px 40px 40px;
        background: #3A2471 url("/img/auth/bg-sign.jpg") no-repeat bottom;
        //padding: 72px 40px 96px 70px;
        color: $white;
        font: 100 16px/24px "Open Sans";

        .auth-side-container{
            max-width: 330px;
            margin: auto;
        }

        .logo {
            margin-bottom: 2.5rem;
        }

        .side-title {
            margin-bottom: 40px;
            font: 300 24px/32px Poppins;
        }

        .external-link{
            position: absolute;
            left: 12%;
            bottom: 104px;
            color: #F9A86B;
            font-weight: bold;
        }
    }

    .auth-main {
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 620px;
        @media (min-height: 795px) {
            padding: 180px 2rem 2rem;
        }
        @media (max-height: 795px) {
            padding: 2rem;
            align-items: center;
        }

        .auth-form-wrapper {
            width: 100%;
            max-width: 400px;
        }

        h4{
            font: 300 24px/32px Poppins;
        }
        .copyright{
            position: absolute;
            left: 17%;
            bottom: 13%;
            color: #9b9a9d;
        }
        a{
            color: #000;
        }
    }

    .form-group {
        position: relative;
    }

    .toggle-password {
        position: absolute;
        top: 16px;
        right: 10px;
        margin: auto;
        font-size: 24px;
        color: $primary;
        cursor: pointer;
    }

}
