// Accordion
.accordion{
    .accordion-item{
        padding: 0 1rem 0;
        border: 1px solid #E2E3E9;
        margin-bottom: 1rem;

        button{
            display: block;
            width: 100%;
            padding: 1rem 0;
            border: 0;
            background: transparent;
            outline: none;
            font-weight: bold;
            text-align: left;
            .las{
                margin-left: .5rem;
                color: $primary;
                font-size: 1rem;
            }
        }
    }
    .accordion-body{
        padding: 1.1rem 0 1rem;
    }
}
