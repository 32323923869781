#paymentForm {
    .form-control:disabled,
    .form-control[readonly] {
        background: $gray-300;
        border-color: $gray-500;
    }
}

.payment-warn {
    font-size: 1rem;
}
