@charset "UTF-8";

@font-face {
    font-family: "castrack";
    src:url("../../fonts/castrack.eot");
    src:url("../../fonts/castrack.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/castrack.woff") format("woff"),
    url("../../fonts/castrack.ttf") format("truetype"),
    url("../../fonts/castrack.svg#castrack") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "castrack" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "castrack" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-archive:before {
    content: "\61";
}
.icon-controls:before {
    content: "\62";
}
.icon-download:before {
    content: "\63";
}
.icon-layers:before {
    content: "\64";
}
.icon-like:before {
    content: "\65";
}
.icon-print:before {
    content: "\66";
}
.icon-settings:before {
    content: "\67";
}
.icon-stopwatch:before {
    content: "\68";
}
.icon-success:before {
    content: "\69";
}
.icon-user:before {
    content: "\6a";
}
.icon-default:before {
    content: "\6b";
}
.icon-calendar:before {
    content: "\6c";
}
.icon-dashboard:before {
    content: "\6d";
}
.icon-doctor:before {
    content: "\6e";
}
.icon-package:before {
    content: "\6f";
}
.icon-scissors:before {
    content: "\70";
}
.icon-3d-printer:before {
    content: "\71";
}
