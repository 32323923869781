.fc{
    /* Toolbar settings */
    .fc-toolbar-ltr{
        .fc-toolbar-chunk{
            display: flex;
            align-items: center;
        }

        .fc-button-primary{
            background: $primary;
        }
    }

    /* Color coding */
    .fc-daygrid-day-events, .fc-popover{

        @each $item, $color in $colors {
            .color-#{$item} {
                background: lighten($color, 30%);
                border-color: lighten($color, 30%);
            }
        }

        .fc-daygrid-event-harness{
            margin-bottom: 4px;
            .fc-event-title{
                padding: 2px 3px;
                color: #252733;
            }
        }

        .fc-daygrid-more-link{
            color: $primary;
        }

    }

    .fc-col-header{
        .fc-col-header-cell-cushion{
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;
            margin-bottom: 8px;
        }
    }

    a{
        color: #252733;
        font-weight: bold;
    }

    .fc-day-past{
        a{
            opacity: 0.4;
        }
    }

    &.fc-theme-standard {
        td{
            border: 0;
        }
        tbody td{
            border: 1px solid #69788C !important;
        }
    }

    th,
    .fc-scrollgrid,
    .fc-col-header{
        border: 0 !important;
    }

    .fc-scrollgrid-section-body{
        background: #f2f2f2;
    }


}

