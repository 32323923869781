/*******************
 Footer
*******************/

.footer {
    padding: 0 43px 0 60px;
    color: $body-color;
    border-top: $border;
    background: $white;
    .footer-inner{
        padding: 1rem;
    }
}
