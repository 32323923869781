.filepond-wrapper{
    border: 3px dashed #CBCFD2;
    border-radius: 8px;
    margin: 15px 0;
}

.filepond--root{
    background: #fff;
    min-height: 295px;

    .filepond--drop-label{
        color: #252733;
        background: #fff;
        min-height: 150px;
        margin-top: 70px;

        > label{
            display: flex;
            flex-direction: column;
            padding-top: 70px;
            justify-content: space-between;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: 0.3px;
            color: #252733;
            background: url('/img/patients/ic-upload-bg.svg') no-repeat center 20px;
        }

        .filepond--label-action{
            display: inline-block;
            padding: 12px 75px;
            margin-top: 15px;
            background: $primary;
            border-radius: 4px;
            color: #fff;
            text-decoration: none;
        }
    }
    .filepond--panel-root{
        background: #fff;
    }
    .filepond--credits{
        display: none;
    }
}

#file-upload-tab.nav-pills{
    border-bottom: 1px solid #ccc;
    .nav-link{
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        color: #252733;
        opacity: 0.6;
        border-radius: 0;
        padding: 0 10px 5px 10px;
        border-color: transparent;
        background-color: white;
        font-weight: 500;

        &.active{
            background: transparent;
            color: $primary;
            border-bottom: 2px solid $primary;
            opacity: 1;
        }
    }
}

#fileUploadModal{
    .close{
        position: absolute;
        top: -20px;
        right: 20px;
        opacity: 1;
    }
}
