// header part

/*******************
 Topbar
*******************/

.topbar {
    position: relative;
    z-index: 10;
    transition: 0.2s ease-in;
    border-bottom: $border;
    background: $white;
    margin-bottom: 1.5rem;
    height: 90px;

    .page-title{
        font: 600 24px/32px Poppins, sans-serif;
        margin: 0;
        a{
            text-decoration: none;
            color: $dark;
        }
    }

    .avatar{
        display: inline-block;
        max-width: 24px;
        max-height: 24px;
        margin-right: .5rem;
    }
}
