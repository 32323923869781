#patients-table {
    tr {
        &:hover {
            background: lighten($primary, 20%);
            color: $white;
            cursor: pointer;

            .las {
                color: $white;
            }
        }
    }
}

.total-info {
    background: $body-bg;

    .total-item {
        .total-item-desc {
            font: normal 12px/16px Poppins;
            color: $sidebar-text;
        }

        .total-item-count {
            font: 500 32px/32px Poppins;
        }
    }
}

.aligners-list {
    li {
        .set-number {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            background: $primary;
            color: $white;
            font-weight: bold;
            margin-right: 5px;
        }
        .active{
            .set-number{
                background: $white;
                color: $dark;
            }
        }
    }
}

.set-files-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .set-file-item{
        width: 190px;
    }
}

.set-file-block{

    position: relative;
    padding: 8px 16px;
    background: #F6F7F8;
    border-radius: 4px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 10px;

    .file-name{
        font-size: 14px;
        color: #252733;
        margin: 10px 0 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .file-size{
        color: #69788C;
        font-size: 12px;
        letter-spacing: 0.4px;
    }
    .file-type{
        color: #69788C;
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;
    }
}

.popover-preview {
    max-width: 400px;

    .popover-body {
        padding: 0;
        min-height: 200px;

        .img-fluid {
            max-width: 330px;
        }
    }
}

.deleteFileOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .progress{
        width: 80%;
    }
}

.analytics-btn{
    svg {
        fill: $primary;
    }

    &:hover {
        svg {
            fill: white;
        }
    }
}
