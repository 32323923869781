// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap';

@import 'core/core';
@import 'layout/layout';
@import 'component/component';
@import 'page/page';

@import "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
@import "~toastr";
@import "~datatables.net-dt";
@import "~filepond/dist/filepond.min.css";
@import "./utilites";
