.datepicker-dropdown:not(.custom) {
    padding: 1rem;
    border: 0;
    box-shadow: 0 0 16px rgba(82, 85, 86, 0.2);
    border-radius: 4px;

    .table-condensed {
        margin: 0;
    }

    &.weeks {
        .datepicker-days {
            tbody {
                tr:hover {
                    background-color: lavender;

                    > td {
                        &:first-child, &:last-child {
                            background: $primary !important;
                            color: $white !important;
                        }
                    }
                }

                tr td.active ~ td {
                    background-color: lavender;
                }
            }
        }
    }

    .datepicker-switch {
        padding-left: 0;
        position: relative;
        right: 43px;
        font-size: 1rem;
    }

    .prev, .next {
        position: absolute;
        top: 1.3rem;
        padding: 0;
        cursor: pointer;
        width: 26px;
        height: 35px;

        &:before {
            position: absolute;
            font-family: 'Line Awesome Free';
            font-weight: 900;
            font-size: 1.5rem;
            background: $white;
            color: #ccc;
        }

        &:hover {
            &:before {
                color: $primary;
            }
        }
    }

    .prev {
        right: 3rem;

        &:before {
            content: "\f104";
        }
    }

    .next {
        right: 1rem;

        &:before {
            content: "\f105";
        }
    }

    .day {
        padding: 14px 13px;
        border: 1px solid #dfdfdf;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        cursor: pointer;

        &.disabled {
            color: darken($white, 20%);
        }

        &.today {
            border: 2px solid #FD919F;
        }
    }

    .year, .day, .month {
        &.active {
            background: $primary !important;
            color: $white !important;
        }
    }

    .day:not(.active), .year:not(.active), .month:not(.active) {
        &:hover {
            background-color: lavender;
        }
    }

    table {
        thead {
            tr {
                td, th {
                    padding: 14px 13px;
                }
            }
        }
    }

    .datepicker-months, .datepicker-years {
        max-width: 250px;

        .datepicker-switch {
            right: 0;
            left: 15px;
        }

        span.month, span.year {
            padding: 15px;
            cursor: pointer;

            &.disabled {
                color: #cccccc;
            }

            &:hover {
                color: $primary;
            }
        }

        thead {
            tr:last-child {
                display: block;
            }
        }

        tbody {
            > tr:first-child {
                td {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }
    }

    .datepicker-years {
        span.year {
            font-family: monospace, monospace;
        }
    }
}
