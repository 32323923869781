// App

/*==============================================================
 For all pages
 ============================================================== */

#page-wrapper {
    position: relative;
    width: 100%;
    background: $body-bg;
    overflow: hidden;
    transition: 0.2s ease-in;
}
