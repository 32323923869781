#stagesDrugDrop{
    position: relative;
    list-style: none;
    padding: 0 0 0 48px;
    margin-bottom: .5rem;

    &:before{
        left: 16px;
        top: 10px;
        bottom: 20px;
        position: absolute;
        content: "";
        width: 1px;
        height: 93%;
        background-color: $primary;
    }

    .step{
        position: absolute;
        left: -48px;
        top: 8px;
        width: 32px;
        height: 32px;
        border: 1px solid $primary;
        background: #B8F2FF;
        color: $primary;
        border-radius: 50%;
        padding: 6px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        z-index: 2;
    }

    li{
        position: relative;
        min-height: 50px;
        max-width: 405px;
        margin-bottom: .5rem;
        border: 1px dashed $primary;
        border-radius: 4px;
        color: $primary;
        font-size: 16px;
        line-height: 24px;
        .sortable-chosen{
            background: rgba(233, 226, 255, 0.8);
        }
        &:hover{
            .act-btn{
                display: block;
            }
        }
        &.sortable-chosen{
            .act-btn{
                display: none;
            }
        }
        &.editable{
            border: 0;
            .act-btn,
            .stage-placeholder{
                display: none;
            }
            .step{
                background: #FFCFCF;
            }
            .form-action-btn{
                width: 140px;
                position: absolute;
                top: 5px;
                left: 100%;
            }
            .form-control{
                border: 1px solid $primary;
                background: transparent;
                padding: 10px;
                min-height: 50px;
                &:invalid{
                    border-color: $danger;
                }
            }
        }
        &.case-complete{
            border: 1px solid $primary;
            background: lighten($primary, 25%);
            color: $white;
        }
        &.locked{
            .act-btn{
                display: none;
            }
        }
    }

    .stage-placeholder{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        min-height: 50px;
        .handle{
            cursor: pointer;
            font-size: 22px;
        }
    }
    form{
        display: none;
    }
    .act-btn{
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
        padding: 10px 0 10px 33px;
        width: 140px;
        button{
            border: 0;
            background: transparent;
            font-size: 1.5rem !important;
            color: $primary;
        }
    }
}



.stage-icons{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem 0 0;
    .title{
        width: 100%;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .icon-item{
        width: 20%;
        font-size: 1.7rem;
        text-align: center;
        margin-bottom: 1rem;
        input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }
        & input:checked ~ .icon {
            color: $orange;
        }
        &:hover input ~ .icon {

        }

        .icon{
            cursor: pointer;
        }
    }
}

.stage-colors{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .title{
        width: 100%;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .color-container{
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 2px solid transparent;
            border-radius: 2px;
            background-color: #fff;
            transition: all .3s ease;
            .las{
                display: none;
                color: $white;
            }
        }

        &.blue{
            .checkmark{
                background: $blue;
            }
        }
        &.indigo{
            .checkmark{
                background: $indigo;
            }
        }
        &.purple{
            .checkmark{
                background: $purple;
            }
        }
        &.pink{
            .checkmark{
                background: $pink;
            }
        }
        &.red{
            .checkmark{
                background: $red;
            }
        }
        &.orange{
            .checkmark{
                background: $orange;
            }
        }
        &.yellow{
            .checkmark{
                background: $yellow;
            }
        }
        &.green{
            .checkmark{
                background: $green;
            }
        }
        &.teal{
            .checkmark{
                background: $teal;
            }
        }
        &.cyan{
            .checkmark{
                background: $cyan;
            }
        }


        &:hover input ~ .checkmark {
            border-radius: 50%;
            transition: all .3s ease;
        }
        & input:checked ~ .checkmark {
            .las{
                display: block;
            }
        }
    }
}
