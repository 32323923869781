#settings_nav{
    li{
        a{
            display: flex;
            align-items: center;
            &.active{
                background: #E9E2FF;
            }
        }
    }
    .las{
        font-size: 2rem;
    }
}
