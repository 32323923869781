.label-preview-modal {
    .modal-dialog {
        max-width: 720px;
    }

    .label-preview {
        width: 100%;
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;

        .page-number {
            text-align: center;
            font-weight: bold;
        }

        .page {
            border: 1px solid black;
            padding: 10px;

            &.odd {
                background: ghostwhite;
            }

            & > .row {
                margin-top: -10px;

                > .col-6 {
                    margin-top: 10px;
                }
            }

            .form-check {
                position: absolute;
                top: 5px;
                left: 20px;

                .form-check-label {
                    cursor: pointer;
                }
            }

            .sticker-wrapper {
                position: relative;
                min-height: 145px;
            }

            .sticker {
                border: 1px solid black;
                border-radius: 5px;
                background: white;
                height: 170px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                overflow: hidden;

                .patient {
                    font-family: Arial;
                    font-size: 16px;
                    text-align: center;
                    font-weight: bold;
                }

                .doctor {
                    font-family: Arial;
                    font-size: 12px;
                }

                .dualine-logo {
                    width: 150px;
                    margin: 0 auto;
                    display: block;
                }

                .upper-lower {
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }
            }
        }
    }
}




