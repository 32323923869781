@import "../variables";

.new-features {
    border-radius: 4px;
    background-color: white;
    padding: 16px;

    margin-bottom: 48px;

    .gap-4 {
        gap: 16px;
    }

    .gap-7 {
        gap: 28px;
    }

    h3 {
        color: #252733;
        font-family: $font-family-base;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.3px;
    }

    p {
        line-height: 151.484%;
        letter-spacing: 0.3px;
        margin: 0;
    }

    a {
        color: map-get($theme-colors, info);
        font-family: $font-family-base;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        text-decoration-line: underline;
    }

    button.x {
        background: none;
        outline: none;
        border: none;
        align-self: self-start;
        padding: 0;
        width: 20px;
        height: 20px;
    }
}
