#edit-set-form {

    .model-item-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 20px;
        border: 1px solid $primary;
        border-radius: 50%;
    }

    .list-group {
        .list-group-item:last-child {
            border-bottom-width: 1px;
        }
    }
}
