.spinner-container {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 50px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
        width: 3rem;
        height: 3rem;
    }
}

.analytics {
    position: relative;

    hr {
        background-color: #E2E3E9;
    }

    .period-unit-dropdown {

        .dropdown-menu {
            > div {
                cursor: pointer;
            }
        }

        button {
            border-color: #AFB9C5;
            background-color: #ffffff !important;
            color: #252733 !important;
            height: 48px;

            &::after {
                display: none;
            }

            .selected-period-unit {
                display: flex;
                align-items: center;

                &::after {
                    content: "\f107";
                    font-family: "Line Awesome Free";
                    color: $primary;
                    border: none;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
        }
    }

    .nav-link {
        background-color: white !important;
        border-color: #dee2e6;

        &.active {
            border-bottom: 0;
        }
    }

    .date-picker {
        position: relative;
        width: 170px;

        img {
            width: 24px;
            height: 24px;
        }

        input {
            padding-left: 40px;

            &[readonly] {
                background-color: white;
            }
        }

        .picker, button {
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            border: none;
            background-color: transparent;
            color: transparent;
            //padding-left: 100%;
        }

        img {
            position: absolute;
            left: 5px;
            transform: translateY(50%);
            top: 0;
        }
    }

    .pie {
        .header {
            width: 95%;
            margin: 0 auto;

            .title {
                font-weight: 700;
                font-size: 14px;
            }

            .total {
                position: relative;
                right: auto;
                top: auto;
            }
        }
    }

    .invalid-period {
        min-height: 25px;
    }

    .chart {
        display: block;
        border: 1px solid #E2E3E9;
        border-radius: 4px;
        position: relative;


        .total, .totals {
            position: absolute;
            z-index: 1;
            font-weight: 600;
            font-size: 14px;
            top: 10px;
            right: 5px;
        }

        .totals {
            > div:not(:first-child) {
                margin-top: 3px;
            }
        }
    }
}

